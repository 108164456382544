<template>
  <span class="add-adhesion-button" v-if="hasPerm('activities.add_activityauthorization')">
    <b-modal
      dialog-class="modal-lg"
      :id="modalId"
      @ok.prevent="onAdd"
      ok-variant="primary"
      cancel-title="Non"
      ok-title="Oui"
      :ok-disabled="!isActive"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Ajout d'une autorisation ?</b>
      </template>
      <div>
        <div class="">
          <b-form-group
            :label="label"
            label-for="activities"
            description=""
          >
            <b-select
              id="activities"
              v-model="selectedActivity"
              :disabled="activities.length === 1"
            >
              <b-select-option v-for="activity of activities" :key="activity.id" :value="activity">
                {{ activity.fullName() }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </div>
        <div class="">
          <b-form-group
            label="Utilisateurs"
            label-for="users"
            description=""
          >
            <b-select
              id="users"
              v-model="selectedUser"
              :disabled="users.length === 1"
            >
              <b-select-option v-for="user of users" :key="user.id" :value="user">
                {{ user.fullName() }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </div>
        <div class="">
          <b-form-group
            label="Type d'accès"
            label-for="access-types"
            :description="selectedAccessTypeInfo"
          >
            <b-select
              id="access-types"
              v-model="selectedAccessType"
            >
              <b-select-option v-for="accessType of accessTypes" :key="accessType.id" :value="accessType">
                {{ accessType.name }}
              </b-select-option>
            </b-select>
          </b-form-group>
        </div>
      </div>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import {
  AuthorizationAccessType, getAccessType, getAccessTypeInfo, makeActivityAuthorization
} from '@/types/activities'
import { makeChoice } from '@/types/base'

export default {
  name: 'add-activity-authorization-modal',
  mixins: [BackendMixin],
  props: {
    activities: Array,
    users: Array,
    label: String,
    modalId: String,
  },
  data() {
    return {
      accessTypes: [],
      selectedActivity: null,
      selectedUser: null,
      selectedAccessType: null,
    }
  },
  watch: {
    activities: function(newValue) {
      if (newValue) {
        if (newValue && newValue.length === 1) {
          this.selectedActivity = newValue[0]
        } else {
          this.selectedActivity = null
        }
      }
    },
    users: function(newValue) {
      if (newValue && newValue.length === 1) {
        this.selectedUser = newValue[0]
      } else {
        this.selectedUser = null
      }
    },
  },
  computed: {
    selectedAccessTypeInfo() {
      if (this.selectedAccessType) {
        return getAccessTypeInfo(this.selectedAccessType.id)
      }
      return ''
    },
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    isActive() {
      return this.selectedActivity && this.selectedUser && this.selectedAccessType
    },
    async onAdd() {
      if (this.isActive()) {
        const url = '/api/activities/activities-authorizations/'
        let backendApi = new BackendApi('post', url)
        const data = {
          activity: this.selectedActivity.id,
          user: this.selectedUser.id,
          'access_type': this.selectedAccessType.id,
        }
        try {
          const resp = await backendApi.callApi(data)
          await this.addSuccess('L\'autorisation a été ajoutée')
          this.$emit('done', makeActivityAuthorization(resp.data))
        } catch (err) {
          this.$bvModal.hide(this.modalId)
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
  mounted() {
    const choices = [AuthorizationAccessType.Allowed, AuthorizationAccessType.Forbidden]
    this.accessTypes = choices.map(
      elt => makeChoice({ id: elt, name: getAccessType(elt), })
    )
  },
}
</script>

<style scoped>

</style>
