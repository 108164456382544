<template>
  <div class="activity-authorizations" v-if="canView">
    <page-header :title="title" :icon="icon" :links="getLinks()">
    </page-header>
    <loading-gif :loading-name="activitiesLoadingName"></loading-gif>
    <loading-gif :loading-name="authorizationsLoadingName"></loading-gif>
    <div v-if="isLoaded && isOk">
      <b-row>
        <b-col cols="8">
          <b-row class="row-header">
            <b-col cols="3">Utilisateur</b-col>
            <b-col cols="4">{{ activitiesLabel() }}</b-col>
            <b-col cols="2">Type d'accès</b-col>
            <b-col cols="2"></b-col>
          </b-row>
          <b-row v-if="isOk && filteredAuthorizations.length === 0" class="row-line">
            <b-col>
              <h3 v-if="selectedUser && selectedUser.id">
                {{ selectedUser.fullName() }} a accès à
                <span v-if="selectedActivity && selectedActivity.id">{{ selectedActivity.fullName() }}</span>
                <span v-else>{{ allLabel }}</span>
              </h3>
              <h3 v-else>
                Tous les utilisateurs ont accès à
                <span v-if="selectedActivity && selectedActivity.id">{{ selectedActivity.fullName() }}</span>
                <span v-else>{{ allLabel }}</span>
              </h3>
            </b-col>
          </b-row>
          <b-row v-for="authorization of filteredAuthorizations" :key="authorization.id" class="row-line">
            <b-col cols="3">
              {{ authorization.user.fullName() }}
            </b-col>
            <b-col cols="5">
              {{ authorization.activity.fullName() }}
            </b-col>
            <b-col cols="2">
              <span v-b-tooltip="authorization.getAccessTypeInfo()">{{ authorization.getAccessType() }}</span>
            </b-col>
            <b-col cols="2" class="text-right">
              <delete-authorization-button
                :authorization="authorization"
                @done="onAuthorizationDeleted"
              >
              </delete-authorization-button>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="4">
          <div class="">
            <b-form-group
              label="Année"
              label-for="school-year"
              description=""
            >
              <b-select
                id="school-year"
                v-model="selectedSchoolYear"
                :disabled="schoolYears.length === 1"
              >
                <b-select-option v-for="schoolYear of schoolYears" :key="schoolYear.id" :value="schoolYear">
                  {{ schoolYear.name }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </div>
          <div class="">
            <b-form-group
              :label="activitiesLabel(true, true)"
              label-for="activities"
              description=""
            >
              <b-select
                id="activities"
                v-model="selectedActivity"
                :disabled="activities.length === 1"
              >
                <b-select-option v-for="activity of activities" :key="activity.id" :value="activity">
                  {{ activity.fullName() }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </div>
          <div class="">
            <b-form-group
              label="Utilisateurs"
              label-for="users"
              description=""
            >
              <b-select
                id="users"
                v-model="selectedUser"
              >
                <b-select-option v-for="user of users" :key="user.id" :value="user">
                  {{ user.fullName() }}
                </b-select-option>
              </b-select>
            </b-form-group>
          </div>
        </b-col>
      </b-row>
    </div>
    <add-authorization-modal
      v-if="isLoaded"
      :users="activeUsers"
      :activities="activeActivities"
      :modal-id="addModalId"
      @done="onAuthorizationAdded"
    >
    </add-authorization-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import DeleteAuthorizationButton from '@/components/Activities/Authorizations/DeleteAuthorizationButton'
import AddAuthorizationModal from '@/components/Activities/Authorizations/AddAuthorizationModal'
import PageHeader from '@/components/Layout/PageHeader'
import LoadingGif from '@/components/Controls/LoadingGif'
import router from '@/router'
import { BackendApi } from '@/utils/http'
import { makeActivity, makeActivityAuthorization } from '@/types/activities'
import { makeSchoolYear } from '@/types/schools'
import { makeStaffUser } from '@/types/users'
import store from '@/store'

export default {
  name: 'ActivitiesAuthorizations',
  mixins: [BackendMixin],
  components: {
    AddAuthorizationModal,
    DeleteAuthorizationButton,
    LoadingGif,
    PageHeader,
  },
  props: {
    youthHome: Number,
  },
  data() {
    return {
      authorizations: [],
      activities: [],
      selectedActivity: null,
      schoolYears: [],
      selectedSchoolYear: null,
      users: [],
      selectedUser: null,
      activitiesLoadingName: 'activitiesLoading',
      authorizationsLoadingName: 'authorizationsLoading',
      isOk: false,
      addModalId: 'bv-modal-add-authorization-button',
    }
  },
  watch: {
    youthHome: function() {
      this.onLoaded()
    },
    selectedSchoolYear: function() {
      if (this.selectedSchoolYear) {
        router.push({ path: this.$route.path, query: { year: this.selectedSchoolYear.id, }, })
      }
      this.loadActivities()
      this.loadActivitiesAuthorizations()
    },
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
    youthHomeIcon() {
      return store.getters.youthHomeIcon
    },
    title() {
      return 'Autorisation d\'accès ' + (this.youthHome ? this.youthHomeLabel : 'aux activités')
    },
    icon() {
      return this.youthHome ? 'fas fa-chess-knight' : this.youthHomeIcon
    },
    isLoaded() {
      return !this.isLoading(this.activitiesLoadingName) && !this.isLoading(this.authorizationsLoadingName)
    },
    canView() {
      return this.hasPerm('activities.view_activityauthorization')
    },
    filteredAuthorizations() {
      let authorizations = this.authorizations
      if (this.selectedSchoolYear && (this.selectedSchoolYear.id > 0) && this.schoolYears.length > 1) {
        authorizations = authorizations.filter(elt => elt.activity.schoolYear.id === this.selectedSchoolYear.id)
      }
      if (this.selectedActivity && (this.selectedActivity.id > 0)) {
        authorizations = authorizations.filter(elt => elt.activity.id === this.selectedActivity.id)
      }
      if (this.selectedUser && (this.selectedUser.id > 0)) {
        authorizations = authorizations.filter(elt => elt.user.id === this.selectedUser.id)
      }
      return authorizations
    },
    allLabel() {
      return this.youthHome ? this.youthHomeLabel : 'toutes les activités'
    },
    activeUsers() {
      if (this.selectedUser && this.selectedUser.id) {
        return [this.selectedUser]
      } else {
        return this.users.filter(elt => elt.id !== 0)
      }
    },
    activeActivities() {
      if (this.selectedActivity && this.selectedActivity.id) {
        return [this.selectedActivity]
      } else {
        return this.activities.filter(elt => elt.id !== 0)
      }
    },
  },
  mounted() {
    this.onLoaded()
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async onLoaded() {
      await this.loadSchoolYears()
      if (this.$route.query.year) {
        const year = +this.$route.query.year
        this.selectedSchoolYear = this.schoolYears.find(elt => elt.id === year) || null
      } else {
        if (this.schoolYears.length >= 1) {
          this.selectedSchoolYear = this.schoolYears[0]
        } else {
          this.selectedSchoolYear = null
        }
      }
      await this.loadActivities()
      await this.loadActivitiesAuthorizations()
      await this.loadUsers()
    },
    async loadActivitiesAuthorizations() {
      this.startLoading(this.authorizationsLoadingName)
      this.isOk = false
      let url = '/api/activities/activities-authorizations/'
      if (this.selectedSchoolYear && this.selectedSchoolYear.id) {
        url += '?school_year=' + this.selectedSchoolYear.id
      }
      const activityIds = this.activities.map(elt => elt.id)
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.authorizations = resp.data.map(elt => makeActivityAuthorization(elt)).filter(
          auth => activityIds.indexOf(auth.activity.id) >= 0
        )
        this.isOk = true
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.authorizationsLoadingName)
    },
    async loadSchoolYears() {
      let url = '/api/school-years/'
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.schoolYears = resp.data.map(
          elt => makeSchoolYear(elt)
        ).filter(
          elt => elt.isActive
        )
        if (this.schoolYears.length > 1) {
          this.schoolYears = [makeSchoolYear({ name: 'Toutes', })].concat(this.schoolYears)
        }
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    async loadActivities() {
      this.startLoading(this.activitiesLoadingName)
      let url = '/api/activities/activities/'
      if (this.youthHome) {
        url = '/api/youth/activities/'
      }
      if (this.selectedSchoolYear && this.selectedSchoolYear.id) {
        url += '?school_year=' + this.selectedSchoolYear.id
      }
      const backendApi = new BackendApi('get', url)
      try {
        const resp = await backendApi.callApi()
        this.activities = [
          makeActivity({ name: this.youthHome ? 'Tous' : 'Toutes', })
        ].concat(resp.data.map(elt => makeActivity(elt)))
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.activitiesLoadingName)
    },
    activitiesLabel(plural = false, uppercase = false) {
      let text = ''
      if (plural) {
        text = this.youthHome ? 'accueils de loisirs' : 'activités'
      } else {
        text = this.youthHome ? 'accueil de loisirs' : 'activité'
      }
      if (uppercase) {
        text = text.toUpperCase()
      }
      return text
    },
    async loadUsers() {
      this.users = []
      let backendApi = new BackendApi('get', '/users/api/users/')
      try {
        let resp = await backendApi.callApi()
        this.users = [makeStaffUser({ last_name: 'Tous', })].concat(
          resp.data.map(elt => makeStaffUser(elt)).filter(user => user.isActive)
        )
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
    },
    onAuthorizationAdded(authorization) {
      this.authorizations.push(authorization)
    },
    onAuthorizationDeleted(authorization) {
      const index = this.authorizations.map(elt => elt.id).indexOf(authorization.id)
      if (index >= 0) {
        this.authorizations.splice(index, 1)
      }
    },
    addAuthorization() {
      this.$bvModal.show(this.addModalId)
    },
    getLinks() {
      const links = []
      if (this.isLoaded && this.hasPerm('activities.add_activityauthorization')) {
        links.push(
          {
            id: 1,
            label: 'Ajouter',
            callback: this.addAuthorization,
            icon: 'fa fa-plus',
            cssClass: 'btn-primary',
          }
        )
      }
      return links
    },
  },
}
</script>

<style lang="less">
</style>
