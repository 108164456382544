<template>
  <span class="delete-adhesion-button" v-if="hasPerm('activities.delete_activityauthorization')">
    <a href @click.prevent="showModal" class="btn btn-xs btn-secondary">
      <i class="fas fa-trash-alt"></i>
      Supprimer
    </a>
    <b-modal
      dialog-class="modal-lg"
      :id="getId()"
      @ok.prevent="onDelete"
      ok-variant="primary"
      cancel-title="Non"
      ok-title="Oui"
    >
      <template v-slot:modal-title>
        <b><i class="fas fa-id-card"></i> Êtes vous sûr de vouloir supprimer cette autorisation ?</b>
      </template>
      <b-row>
        <b-col>
          {{ authorization.activity.fullName() }} pour {{ authorization.user.fullName() }}:
          {{ authorization.getAccessType() }}
        </b-col>
      </b-row>
      <div v-if="errorText" class="error-text">
        <i class="fa fa-error"></i> {{ errorText }}
      </div>
    </b-modal>
  </span>
</template>

<script>

import { mapActions } from 'vuex'
import { BackendMixin } from '@/mixins/backend'
import { getRandomId } from '@/utils/random'
import { BackendApi } from '@/utils/http'

export default {
  name: 'delete-activity-authorization-button',
  mixins: [BackendMixin],
  props: {
    authorization: Object,
  },
  data() {
    return {
      user: null,
      randomId: getRandomId(),
      errorText: '',
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    showModal() {
      this.$bvModal.show(this.getId())
    },
    getId() {
      return 'bv-modal-delete-authorization-button' + this.randomId
    },
    async onDelete() {
      const url = '/api/activities/activities-authorizations/' + this.authorization.id + '/'
      let backendApi = new BackendApi('delete', url)
      try {
        await backendApi.callApi()
        await this.addSuccess('L\'autorisation a été supprimée')
        this.$bvModal.hide(this.getId())
        this.$emit('done', this.authorization)
      } catch (err) {
        this.errorText = this.getErrorText(err)
      }
    },
  },
}
</script>

<style scoped>
</style>
